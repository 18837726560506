import {Box, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import jar from "../../assets/images/markus-donation-wb.png";
import {TableButton} from "./index";
import monoIcon from "../../assets/images/mono-icon.png";
import copyIcon from "../../assets/images/copy-icon.png";
import * as React from "react";

export default function DronesFundraiser() {
    return (
        <Box className={'fundraiser'}>
            <Typography
                className={'fundraiser-title'}><span
                style={{fontWeight: 600, color: 'rgb(181, 139, 91)'}}>3 700 000 ГРН</span> ДЛЯ ЗАКУПІВЛІ FPV-ДРОНІВ,
                НАЗЕМНИХ СТАНЦІЙ, ЗАСОБІВ
                РЕБ ДЛЯ 47 ОМБр
            </Typography>

            <Box sx={{border: '2px solid #A3BAD4', my: '2rem', p: '2rem', position: 'relative'}}>

                <Table className={'small'}>
                    <TableBody>
                        <TableRow className={'no-border'}>
                            <TableCell>Моно Банк:</TableCell>
                            <TableCell>
                                <Typography></Typography>
                                <Box>
                                    <img id={'monobank-jar'} style={{width: '50px'}} src={jar} alt={'Монобанка'}/>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <TableButton icon={monoIcon}
                                             class={'small'}
                                             href={'https://send.monobank.ua/jar/6eV2c6enbF'}>Поповнити</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{borderColor: '#A3BAD4 !important'}}>PayPal:</TableCell>
                            <TableCell sx={{borderColor: '#A3BAD4 !important'}}>vvs.vasiliev@gmail.com</TableCell>
                            <TableCell sx={{borderColor: '#A3BAD4 !important'}}>
                                <TableButton icon={copyIcon}
                                             class={'small'}
                                             textToCopy={'vvs.vasiliev@gmail.com'}>Копіювати</TableButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <Typography sx={{fontSize: '14px', textAlign: 'left'}}>Підсильте та захиститіть військових 47
                    ОМБр!</Typography>
                <Typography sx={{fontSize: '14px', textAlign: 'left', mt: 1}}>Збираємо на 100 FPV-дронів для групи
                    ударних БпАК 2-го механізованого батальйону, наземні станції (для підсилення сигналів дронів) та
                    засоби РЕБ на авто й техніку 47-ї окремої механізованої бригади. </Typography>
                <Typography sx={{fontSize: '14px', textAlign: 'left', mt: 1}}>
                    Ваш донейт на цей збір не лише підсилює військових 47 ОМБр, а також підтримує проєкт ветеранів 47
                    ОМБр (<a style={{color: '#26abd3', cursor: 'pointer'}}
                    href={"https://www.instagram.com/flyinghead.drones"}
                    target={'_blank'}>Flying Head</a>).
                </Typography>
                <Typography sx={{fontSize: '14px', textAlign: 'left', mt: 1}}>
                    Саме ветерани Flying Head (Летюча голова) вже розробляють та збирають необхідне обладнання в
                    тісному контакті з військовими 47 ОМБр, що забезпечить його подальше ефективне використання
                    військом.
                </Typography>

                <Typography sx={{fontSize: '14px', mt: '1rem'}}>Подробиці в соцмережах фонду:&nbsp;
                    <a href={"https://www.facebook.com/bf.markus.foundation"}
                       style={{color: '#26abd3', cursor: 'pointer'}} target={'_blank'}>Facebook</a> | <a
                        href={"https://www.instagram.com/markus.foundation/"}
                        style={{color: '#26abd3', cursor: 'pointer'}} target={'_blank'}>Instagram</a> | <a
                        href={"https://t.me/markus_foundation"} style={{color: '#26abd3', cursor: 'pointer'}}
                        target={'_blank'}>Telegram</a>
                </Typography>
            </Box>
        </Box>
    )
}
